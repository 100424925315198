<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<el-button type="primary" size="mini">{{$t('sycm.actions.realTime')}}</el-button>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div>
					<div class="title">{{$t('sycm.ipoll.goodsRank')}}</div>
					<ul class="head-menu">
						<li :class="{ active: activeTab == 0 }" @click="handleMenu(0)">{{$t('sycm.common.visitor')}}TOP50</li>
						<li :class="{ active: activeTab == 1 }" @click="handleMenu(1)">{{$t('sycm.common.addBuys')}}TOP50</li>
						<li :class="{ active: activeTab == 2 }" @click="handleMenu(2)">{{$t('sycm.common.payMoney')}}TOP50</li>
					</ul>
				</div>
				<ul class="barbox">
					<li class="bar-item">
						<el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
							<span class="el-dropdown-link">
								{{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</li>
					<li class="bar-item">
						<el-input v-model="keyword" :placeholder="$t('sycm.placeholder.searchGoods')" size="small" @change="handleSearch">
							<i slot="suffix" class="el-icon-search" style="cursor: pointer" @click="handleSearch"></i>
						</el-input>
					</li>
				</ul>
			</div>
			<div class="section-main">
				<el-table :data="rankData">
					<el-table-column :label="$t('sycm.tableHead.rank')" type="index"></el-table-column>
					<el-table-column :label="$t('sycm.tableHead.goodsName')" min-width="240px">
						<template slot-scope="scope">
							<div class="goods-item">
								<div class="item-pic"><img :src="scope.row.goods_image" alt=""></div>
								<div class="item-main">
									<div class="name">{{scope.row.goods_name}}</div>
									<!-- <div class="text">{{$t('sycm.ipoll.meanwhile')}}</div> -->
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-if="activeTab == 1" :label="$t('sycm.common.addBuys')" align="right" key="2" prop="add_carts">
						<template slot="header">
							<span>{{$t('sycm.common.addBuys')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-text">{{$t('sycm.popover.addBuys1')}}</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column v-if="activeTab !== 1" :label="$t('sycm.common.views')" align="right" key="3" prop="views">
						<template slot="header">
							<span>{{$t('sycm.common.views')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.views')+'”'}}</p>
									<p>{{$t('sycm.popover.views1')}}</p>
								</div>
								<div class="popover-main">
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.range')}}</div>
										<div class="item-block">{{$t('sycm.popover.views2')}}</div>
									</div>
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.repeat')}}</div>
										<div class="item-block">{{$t('sycm.popover.views3')}}</div>
									</div>
								</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="visitors" align="right">
						<template slot="header">
							<span>{{$t('sycm.common.visitor')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.visitor')+'”'}}</p>
									<p>{{$t('sycm.popover.visitor1')}}</p>
								</div>
								<div class="popover-main">
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.range')}}</div>
										<div class="item-block">{{$t('sycm.popover.visitor2')}}</div>
									</div>
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.repeat')}}</div>
										<div class="item-block">{{$t('sycm.popover.visitor3')}}</div>
									</div>
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.wave')}}</div>
										<div class="item-block">{{$t('sycm.popover.visitor4')}}</div>
									</div>
								</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.common.payMoney')" prop="pay_price" align="right">
						<template slot="header">
							<span>{{$t('sycm.common.payMoney')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.payMoney')+'”'}}</p>
									<p>{{$t('sycm.popover.payMoney1')}}</p>
								</div>
								<div class="popover-main">
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.range')}}</div>
										<div class="item-block">{{$t('sycm.popover.payMoney2')}}</div>
									</div>
								</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.common.payBuyers')" prop="pay_buyers" align="right"></el-table-column>
					<el-table-column :label="$t('sycm.common.payConversion')" prop="pay_conver_rate" align="right">
						<template slot="header">
							<span>{{$t('sycm.common.payConversion')}}</span>
							<el-popover placement="bottom-start" width="477" trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.payConversion')+'”'}}</p>
									<p>{{$t('sycm.popover.payConversion1')}}</p>
								</div>
								<div class="popover-main">
									<el-row :gutter="30">
										<el-col :span="12">
											<div class="inversion-item">
												<div class="item-name red"><i class="el-icon-top"></i>{{$t('sycm.popover.payConversion2')}}</div>
												<div class="item-box">{{$t('sycm.popover.payConversion3')}}</div>
											</div>
										</el-col>
										<el-col :span="12">
											<div class="inversion-item">
												<div class="item-name green"><i class="el-icon-bottom"></i>{{$t('sycm.popover.payConversion4')}}</div>
												<div class="item-box">{{$t('sycm.popover.payConversion5')}}</div>
											</div>
										</el-col>
									</el-row>
								</div>
							</el-popover>
						</template>
						<template slot-scope="scope">
							{{scope.row.pay_conver_rate}}%
						</template>
					</el-table-column>
					<!-- <el-table-column :label="$t('sycm.tableHead.actions')"></el-table-column> -->
				</el-table>
				<div style="text-align: right;margin-top: 10px;">
					<el-pagination :total="pageTotal" :current-page="pageCurr" :page-size="pageSize"
						@current-change="handleCurrentChange" @size-change="handleSizeChange" layout="prev, pager, next"
						:prev-text="$t('sycm.actions.prevPage')" :next-text="$t('sycm.actions.nextPage')"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchIpollRank } from "@/api/sycm"
export default {
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		}
	},
	data() {
		return {
			terminalType: '',
			keyword: '',
			activeTab: 0,
			rankData: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 10,
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData() {
			const loading = this.$loading()
			try {
				const params = {
					type: this.activeTab,
					keyword: this.keyword,
					source: this.terminalType,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_siez: this.pageSize
				}
				const {data} =  await fetchIpollRank(params)
				this.rankData = data.list
				this.pageTotal = data.count?parseInt(data.count):0
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		handleCurrentChange(val) {
			this.pageCurr = val
			this.getData()
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.getData()
		},
		handleMenu(val) {
			this.activeTab = val
			this.pageCurr = 1
			this.getData()
		},
		handleSearch(){
			this.pageCurr = 1
			this.getData()
		},
		handleCommand(command) {
			this.terminalType = command
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;

				.el-popover__reference {
					margin-left: 6px;
					color: #999999;
					font-size: 14px;
					cursor: pointer;
				}
			}

			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;

				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}

			.barbox {
				display: flex;

				.bar-item {
					margin-right: 10px;
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;

			.bar-item {
				margin-left: 10px;
			}
		}
	}

	.goods-item {
		display: flex;
		width: 240px;
		padding-right: 10px;

		.item-pic {
			width: 72px;
			height: 72px;
			margin-right: 10px;
			border: 1px solid #e6e6e6;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-main {
			width: 0;
			flex: 1;
			font-size: 12px;
			line-height: 1.5;

			.name {
				color: $--sycm-primary;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.text {
				margin-top: 6px;
				color: #999999;
			}
		}
	}
}
</style>